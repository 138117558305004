<template>
  <v-container
    id="tracking-list"
    tag="section"
    fluid
  >
    <v-sheet
      elevation="3"
      rounded
      class="mb-4"
    >
      <v-container
        fluid
      >
        <v-row
          dense
        >
          <v-col
            cols="12"
            md="3"
            lg="2"
          >
            <v-text-field
              v-model="filters['order_item:product.sku:like']"
              :label="$t('views.tracking.forms.labels.sku')"
              prepend-inner-icon="mdi-wardrobe-outline"
              hide-details
              clearable
              outlined
              dense
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            lg="2"
          >
            <v-text-field
              v-model="filters['order_item:order_reference']"
              :label="$t('views.tracking.forms.labels.order_reference')"
              prepend-inner-icon="mdi-cart-variant"
              hide-details
              clearable
              outlined
              dense
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            lg="2"
          >
            <v-menu
              v-model="menu.order_item_created_datetime_gte_menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filters['order_item:created_datetime:gte']"
                  :label="$t('views.tracking.forms.labels.order_item_created_datetime_from')"
                  prepend-inner-icon="mdi-calendar"
                  hide-details
                  clearable
                  outlined
                  dense
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="filters['order_item:created_datetime:gte']"
                @input="menu.order_item_created_datetime_gte_menu = false"
              />
            </v-menu>
          </v-col>
          <v-col
            cols="12"
            md="3"
            lg="2"
          >
            <v-menu
              v-model="menu.order_item_created_datetime_lte_menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filters['order_item:created_datetime:lte']"
                  :label="$t('views.tracking.forms.labels.order_item_created_datetime_to')"
                  prepend-inner-icon="mdi-calendar"
                  hide-details
                  clearable
                  outlined
                  dense
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="filters['order_item:created_datetime:lte']"
                @input="menu.order_item_created_datetime_lte_menu = false"
              />
            </v-menu>
          </v-col>
          <v-col
            cols="12"
            md="3"
            lg="2"
          >
            <StoreSelector
              v-model="filters['order_item:store.id']"
              filter
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            lg="2"
          >
            <v-select
              v-model="filters['order_item:status']"
              :items="values.order_item_status"
              :label="$t('views.tracking.forms.labels.order_item_status')"
              hide-details
              prepend-inner-icon="mdi-state-machine"
              item-text="label"
              item-value="value"
              clearable
              outlined
              dense
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            lg="2"
          >
            <v-select
              v-model="filters['shipment:provider']"
              hide-details
              clearable
              outlined
              dense
              item-text="label"
              item-value="value"
              :items="values.providers"
              prepend-inner-icon="mdi-dolly"
              label="Logisticien"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            lg="2"
          >
            <v-select
              v-model="filters['shipment:status']"
              :items="values.shipment_status"
              :label="$t('views.tracking.forms.labels.shipment_status')"
              hide-details
              prepend-inner-icon="mdi-truck-fast-outline"
              item-text="label"
              item-value="value"
              clearable
              outlined
              dense
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            lg="2"
          >
            <v-text-field
              v-model="filters['shipment:tracking_number:like']"
              :label="$t('views.tracking.forms.labels.shipment_tracking_number')"
              hide-details
              prepend-inner-icon="mdi-package-variant"
              clearable
              outlined
              dense
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            lg="2"
          >
            <v-text-field
              v-model="filters['shipment:recipient_email:like']"
              :label="$t('views.tracking.forms.labels.recipient_email')"
              hide-details
              prepend-inner-icon="mdi-at"
              clearable
              outlined
              dense
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            lg="2"
          >
            <v-select
              v-model="filters['order_item:product.in_imparfaite_warehouse']"
              hide-details
              clearable
              outlined
              dense
              item-text="label"
              item-value="value"
              prepend-inner-icon="mdi-warehouse"
              :items="values.in_imparfaite_warehouse"
              :label="$t('views.products.filters.in_imparfaite_warehouse')"
            />
          </v-col>
          <v-col
            cols="12"
            md="2"
            lg="1"
          >
            <v-select
              v-model="filters['shipment:kind']"
              hide-details
              clearable
              outlined
              dense
              item-text="label"
              item-value="value"
              prepend-inner-icon="mdi-home-account"
              :items="values.shipment_kind"
              label="Gestion interne"
            />
          </v-col>
          <v-spacer />
          <v-col
            class="text-right"
            cols="12"
            md="2"
            lg="1"
          >
            <v-btn
              outlined
              color="blue darken-2"
              @click="load()"
            >
              <v-icon color="blue darken-2">
                mdi-refresh
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-sheet
      elevation="3"
      rounded
    >
      <v-data-table
        :options.sync="table.options"
        :loading="table.loading"
        :loading-text="$t('global.loading')"
        :no-data-text="$t('global.no-data')"
        :items="items"
        :server-items-length="totalItems"
        :headers="table.headers"
        :footer-props="table.footerProps"
      >
        <template v-slot:loading>
          <v-sheet
            class="text-center pa-6"
          >
            {{ $t('global.loading') }}
          </v-sheet>
        </template>
        
        <template v-slot:no-data>
          <v-sheet
            class="text-center pa-6"
          >
            {{ $t('global.no-data') }}
          </v-sheet>
        </template>

        <template v-slot:item.color="{ item }">
          <div
            class="ma-0"
            :style="getColor(item)"
          >
            <v-btn
              icon
              small
              outlined
              class="my-2"
              :title="$t('views.tracking.forms.labels.filter_by_order_id')"
              color="brown lighten-5"
              elevation="15"
              @click="filterBy('order_item:order_reference', item.order.order_reference)"
            >
              <v-icon small>
                mdi-cart-outline
              </v-icon>
            </v-btn>
            <v-divider />
            <v-btn
              icon
              small
              outlined
              class="my-2"
              :title="$t('views.tracking.forms.labels.filter_by_product_sku')"
              color="brown lighten-5"
              elevation="15"
              @click="filterBy('order_item:product.sku:like', item.order.product_sku)"
            >
              <v-icon small>
                mdi-wardrobe-outline
              </v-icon>
            </v-btn>
            <v-divider />
            <v-btn
              icon
              small
              outlined
              class="my-2"
              :title="$t('views.tracking.forms.labels.filter_by_store_name')"
              color="brown lighten-5"
              elevation="15"
              @click="filterBy('order_item:store.id', item.order.store_id)"
            >
              <v-icon small>
                mdi-store-outline
              </v-icon>
            </v-btn>
            <v-divider />
            <v-btn
              icon
              small
              outlined
              class="my-2"
              :title="$t('views.tracking.forms.labels.filter_by_recipient_email')"
              color="brown lighten-5"
              elevation="15"
              @click="filterBy('shipment:recipient_email:like', item.shipments.toCustomer.recipient_email)"
            >
              <v-icon small>
                mdi-account-outline
              </v-icon>
            </v-btn>
          </div>
        </template>
        <template v-slot:item.order="{ item }">
          <v-card
            outlined
            color="transparent"
          >
            <v-card-title class="pt-1 pb-1">
              <v-btn
                icon
                target="_blank"
                class="mr-2"
                :href="getImparfaiteOrderLink(item.order.order_id)"
              >
                <v-icon
                  color="indigo lighten-2"
                >
                  mdi-open-in-new
                </v-icon>
              </v-btn>{{ item.order.order_reference }}
            </v-card-title>
            <v-card-text class="pt-1 pb-1">
              <StatusLabel :item="item.order" />
            </v-card-text>
            <v-card-subtitle class="pt-1 pb-1">
              {{ item.order.created_datetime | date }}
            </v-card-subtitle>
            <v-card-subtitle class="pt-1 pb-1">
              <span v-if="item.shipments.toCustomer">
                {{ item.shipments.toCustomer.recipient_address_recipient_name }}
              </span>
              <span v-else>
                no recipient
              </span>
            </v-card-subtitle>
          </v-card>
        </template>
        <template v-slot:item.product="{ item }">
          <v-card
            outlined
            color="transparent"
          >
            <v-card-title class="pt-1 pb-1">
              {{ item.order.product_name }}
            </v-card-title>
            <v-list-item two-line>
              <v-list-item-avatar
                tile
                size="60"
                class="mt-0 mb-0"
                color="grey"
              >
                <v-img :src="item.order.product_photo_url" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-subtitle class="pt-2 pb-2">
                  {{ item.order.product_sku }} - {{ item.order.product_sizes }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <img
                    v-if="item.order.store_country_code.toLowerCase() === 'bel'"
                    src="../../assets/bel.svg"
                    alt="Belgique"
                    width="16"
                    class="mr-1"
                  >
                  <img
                    v-else
                    src="../../assets/fra.svg"
                    alt="France"
                    width="16"
                    class="mr-1"
                  >
                  {{ item.order.store_name }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-card-actions>
              <v-icon
                v-if="item.order.product_in_imparfaite_warehouse"
                small
                left
                color="red accent-3"
              >
                mdi-warehouse
              </v-icon>
              <v-icon
                v-else
                small
                left
                color="grey lighten-2"
              >
                mdi-warehouse
              </v-icon>
              <v-btn
                icon
                color="indigo lighten-2"
                link
                target="_blank"
                :href="'/products/'+item.order.product_id"
              >
                <v-icon small>
                  mdi-square-edit-outline
                </v-icon>
              </v-btn>
              <v-btn
                icon
                color="indigo lighten-2"
                link
                target="_blank"
                :href="item.order.product_url"
              >
                <v-icon small>
                  mdi-open-in-new
                </v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
        <template v-slot:item.order_shipping_to_customer_status="{ item }">
          <ShipmentListDetail
            v-if="item.shipments.toCustomer"
            :shipment="item.shipments.toCustomer"
          />
        </template>
        <template v-slot:item.order_administrator_notes="{ item }">
          <v-row
            class="mb-1 mt-0"
            no-gutters
          >
            <v-col
              md="4"
              offset-md="8"
            >
              <v-switch
                v-model="item.order.ready_to_be_prepared_for_customer"
                @change="certifyConform(item)"
              />
            </v-col>
          </v-row>
          <v-row>
            {{ item.order.administrator_notes }}
          </v-row>
        </template>
        <template v-slot:item.order_information="{ item }">
          <v-list
            outlined
            color="transparent"
          >
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="blue lighten-2">
                  mdi-cash-fast
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  <span
                    class="font-weight-thin"
                  > {{ (item.order.amount_detail_received_amount/100)|currency }}</span>
                  -
                  <span
                    class="font-weight-bold light-blue--text"
                  > {{ (item.order.amount_detail_paid_amount/100)|currency }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <template v-if="item.order.available_for_transfer_at">
                <v-list-item-icon>
                  <v-icon color="green lighten-2">
                    mdi-invoice-clock-outline
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    {{ $t('views.tracking.forms.labels.available_for_transfer_at') }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ item.order.available_for_transfer_at|dateSimple }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
              <template v-else-if="item.order.withdrawal_period_ended_at">
                <v-list-item-icon>
                  <v-icon color="orange lighten-2">
                    mdi-message-badge-outline
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    {{ $t('views.tracking.forms.labels.withdrawal_period_ended_at') }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ item.order.withdrawal_period_ended_at|dateSimple }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-list-item>
            <v-list-item v-if="item.order.cancellation_reason">
              <v-list-item-icon>
                <v-icon color="red lighten-2">
                  mdi-web-cancel
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle>
                  {{ $t('views.tracking.forms.labels.cancellation_reason') }}
                </v-list-item-subtitle>
                {{ item.order.cancellation_reason }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="item.order.refunded_datetime">
              <v-list-item-icon>
                <v-icon color="green lighten-2">
                  mdi-credit-card-refund-outline
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle>
                  {{ $t('views.tracking.forms.labels.refunded_datetime') }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  {{ item.order.refunded_datetime|date }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-else-if="item.order.pending_refund_datetime">
              <v-list-item-icon>
                <v-icon color="orange lighten-2">
                  mdi-calendar-question
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle>
                  {{ $t('views.tracking.forms.labels.pending_refund_datetime') }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  {{ item.order.pending_refund_datetime|date }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="item.order.transfer_id">
              <v-list-item-icon>
                <v-icon color="green lighten-2">
                  mdi-bank-transfer-in
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle>
                  {{ $t('views.tracking.forms.labels.order_transferred') }}
                &nbsp;
                  {{ item.order.transferred_at|date }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="font-weight-thin">
                  {{ item.order.transfer_id }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="item.order.payout_id">
              <v-list-item-icon>
                <v-icon color="orange lighten-2">
                  mdi-bank-transfer-out
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle>
                  {{ $t('views.tracking.forms.labels.order_paid_out') }}{{ item.order.paid_out_at|date }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="font-weight-thin">
                  {{ item.order.payout_id }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
        <template v-slot:item.menu="{ item }">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list-item
              v-if="item.order.status === 'PENDING_CUSTOMER_ACCEPTANCE' || item.order.status === 'PENDING_DELIVERY'"
              @click="openReturnOrderDialog(item)"
            >
              <v-list-item-title>
                <v-icon left>
                  mdi-cash-refund
                </v-icon>
                {{ $t('views.tracking.menu.return') }}
              </v-list-item-title>
            </v-list-item>

            <v-list>
              <v-list-item
                v-if="item.order.status === 'PENDING_DELIVERY'"
                @click="openDeliveryDialog(item)"
              >
                <v-list-item-title>
                  <v-icon left>
                    mdi-calendar-alert
                  </v-icon>
                  {{ $t('views.tracking.menu.add_delivery_date') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="openNotesDialog(item)">
                <v-list-item-title>
                  <v-icon left>
                    mdi-information
                  </v-icon>
                  {{ $t('views.tracking.menu.update_notes') }}
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="item.order.transfer_id === null"
                @click="openTransferDialog(item)"
              >
                <v-list-item-title>
                  <v-icon left>
                    mdi-wallet-plus-outline
                  </v-icon>
                  {{ $t('views.tracking.menu.transfer') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="item.order.status === 'PENDING_REFUND'"
                @click="returnReceived(item)"
              >
                <v-list-item-title>
                  <v-icon left>
                    mdi-check
                  </v-icon>
                  {{ $t('views.tracking.menu.return_received') }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-sheet>
    
    <ConfirmModal
      :is-open="deliveryDialog.visible"
      :title="$t('views.tracking.dialog.admin.add_delivery_date.title')"
      sub-text="/!\/!\ Mieux vaut utiliser la Livraison complète d'un colis. /!\/!\"
      :handle-submit="executeAddDelivery"
      :handle-cancel="close"
    >
      <div class="text-center">
        <v-date-picker
          v-model="deliveryDialog.date"
          no-title
        />
      </div>
    </ConfirmModal>
    <ConfirmModal
      :is-open="dialog.visible"
      :title="$t('views.tracking.dialog.admin.transfer.title')"
      :text="dialog.text"
      :sub-text="$t('views.tracking.dialog.admin.transfer.subtext')"
      :handle-submit="executeTransfer"
      :handle-cancel="close"
    />
    <ConfirmModal
      :is-open="returnDialog.visible"
      :title="$t('views.tracking.dialog.admin.return.title')"
      :text="returnDialog.text"
      :sub-text="$t('views.tracking.dialog.admin.return.subtext')"
      :handle-cancel="close"
    >
      <template #actions>
        <v-btn
          outlined
          color="red darken-2"
          @click="close"
        >
          <v-icon right>
            mdi-cancel
          </v-icon>{{ $t('forms.buttons.cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          raised
          color="#f9dbc2"
          @click="executeReturn('warehouse')"
        >
          <v-icon left>
            mdi-check-outline
          </v-icon>A l'atelier
        </v-btn>
        <v-spacer />
        <v-btn
          raised
          color="green lighten-1"
          @click="executeReturn('store')"
        >
          <v-icon left>
            mdi-check-outline
          </v-icon>Vendeur
        </v-btn>
      </template>
    </ConfirmModal>
    <ConfirmModal
      :is-open="adminDialog.visible"
      :title="$t('views.tracking.dialog.admin.update_notes.title')"
      :text="adminDialog.text"
      :handle-submit="saveAdminDialog"
      :handle-cancel="close"
    >
      <v-row>
        <v-textarea
          v-model="adminDialog.notes"
          background-color="amber lighten-4"
          color="orange orange-darken-4"
          label="Note"
        />
      </v-row>
    </ConfirmModal>
    <Snackbar />
  </v-container>
</template>

<script>
import ConfirmModal from '@/components/core/ConfirmModal.vue'
import { createQueryString } from '@/helpers/createQueryString'
import { debounce } from '@/helpers/debounce'
import EventBus from '@/plugins/event-bus'
import ShipmentListDetail from '@/components/core/ShipmentListDetail'
import Snackbar from '@/components/core/Snackbar'
import StatusLabel from '@/components/core/StatusLabel'
import StoreSelector from '@/components/base/StoreSelector.vue'

export default {
  name: 'TrackingList',
  components: {
    ConfirmModal,
    Snackbar,
    StatusLabel,
    StoreSelector,
    ShipmentListDetail,
  },
  data() {
    const currentPage = +this.$route.query.page || 1

    return {
      currentPage,
      dialog:{
        visible: false,
        text: '',
      },
      deliveryDialog:{
        visible: false,
        date: null,
      },
      returnDialog:{
        visible: false,
        text: '',
      },
      adminDialog:{
        visible: false,
        text: '',
        notes: '',
        conform: '',
      },
      editedItem: {
        title: '',
        id: '',
      },
      defaultItem: {
        id: '',
      },
      store: {},
      request: null,
      table: {
        loading: false,
        options: {
          page: currentPage,
          itemsPerPage: 50,
        },
        headers: [
          {
            text: '-',
            value: 'color',
            width: 30,
            align: 'center',
            sortable: false,
          }, {
            text: this.$i18n.t('views.tracking.headers.order'),
            value: 'order',
            width: 300,
            sortable: false,
          },
          {
            text: this.$i18n.t('views.tracking.headers.product'),
            value: 'product',
            width: 300,
            sortable: false,
          },
          {
            text: this.$i18n.t('views.tracking.headers.to_customer_shipping'),
            value: 'order_shipping_to_customer_status',
            sortable: false,
            width: 320,
          },
          {
            text: this.$i18n.t('views.tracking.headers.order_administrator_notes'),
            value: 'order_administrator_notes',
            sortable: false,
          },
          {
            text: this.$i18n.t('views.tracking.headers.order_information'),
            value: 'order_information',
            sortable: false,
          },
          {
            text: '',
            value: 'menu',
            sortable: false,
            width: '50px',
          },
        ],
        footerProps: {
          'items-per-page-options': [50],
          'show-first-last-page': true,
          'show-current-page': true,
        },
      },
      items: [],
      totalItems : 0,
      filters: {
        'order_item:order_reference': this.$route.query['order_item:order_reference'],
        'shipment:status': this.$route.query['shipment:status'],
        'order_item:status': this.$route.query['order_item:status'],
        'order_item:product.sku:like': this.$route.query['order_item:product.sku:like'],
        'order_item:product.in_imparfaite_warehouse': this.$route.query['order_item:product.in_imparfaite_warehouse'],
        'order_item:store.id' : this.$route.query['order_item:store.id'],
        'shipment:recipient_email:like' : this.$route.query['shipment:recipient_email:like'],
        'shipment:kind' : this.$route.query['shipment:kind'],
        'shipment:provider' : this.$route.query['shipment:provider'],
        'shipment:senderAddressCountryCode' : this.$route.query['shipment:senderAddressCountryCode'],
        'order_item:created_datetime:lte' : this.$route.query['order_item:created_datetime:lte'],
        'order_item:created_datetime:gte' : this.$route.query['order_item:created_datetime:gte'],
        'shipment:tracking_number:like' : this.$route.query['shipment:tracking_number:like'],
      },
      menu: {
        order_item_created_datetime_gte_menu: false,
        order_item_created_datetime_lte_menu: false,
      },
      values: {
        shipment_status: [
          {
            label: this.$i18n.t('views.tracking.forms.enum.shipment_status.AWAITING_LABEL_GENERATION'),
            value:'AWAITING_LABEL_GENERATION',
          },
          {
            label: this.$i18n.t('views.tracking.forms.enum.shipment_status.PENDING_DEPOSIT'),
            value:'PENDING_DEPOSIT',
          },
          {
            label: this.$i18n.t('views.tracking.forms.enum.shipment_status.IN_TRANSIT'),
            value:'IN_TRANSIT',
          }, {
            label: this.$i18n.t('views.tracking.forms.enum.shipment_status.DELIVERED'),
            value:'DELIVERED',
          },
          {
            label: this.$i18n.t('views.tracking.forms.enum.shipment_status.PICKUP_DELIVERED'),
            value:'PICKUP_DELIVERED',
          }, {
            label: this.$i18n.t('views.tracking.forms.enum.shipment_status.RETURNED'),
            value:'RETURNED',
          },
        ],
        order_item_status: [
          {
            label: this.$i18n.t('views.tracking.forms.enum.order_item_status.pending_delivery'),
            value:'PENDING_DELIVERY',
          },
          {
            label: this.$i18n.t('views.tracking.forms.enum.order_item_status.pending_customer_acceptance'),
            value:'PENDING_CUSTOMER_ACCEPTANCE',
          },
          {
            label: this.$i18n.t('views.tracking.forms.enum.order_item_status.available_for_transfer'),
            value:'AVAILABLE_FOR_TRANSFER',
          },
          {
            label: this.$i18n.t('views.tracking.forms.enum.order_item_status.transferred'),
            value:'TRANSFERRED',
          },
          {
            label: this.$i18n.t('views.tracking.forms.enum.order_item_status.pending_payout'),
            value:'PENDING_PAYOUT',
          },
          {
            label: this.$i18n.t('views.tracking.forms.enum.order_item_status.paid'),
            value:'PAID',
          },
          {
            label: this.$i18n.t('views.tracking.forms.enum.order_item_status.pending_refund'),
            value:'PENDING_REFUND',
          },
          {
            label: this.$i18n.t('views.tracking.forms.enum.order_item_status.returned'),
            value:'RETURNED',
          },
          {

            label: this.$i18n.t('views.tracking.forms.enum.order_item_status.refunded'),
            value:'REFUNDED',
          },
          {
            label: this.$i18n.t('views.tracking.forms.enum.order_item_status.archived'),
            value:'ARCHIVED',
          },
          {
            label: this.$i18n.t('views.tracking.forms.enum.order_item_status.anomaly'),
            value:'ANOMALY',
          },
          {
            label: this.$i18n.t('views.tracking.forms.enum.order_item_status.merged'),
            value:'MERGED',
          },
          {
            label: this.$i18n.t('views.tracking.forms.enum.order_item_status.pending_cancellation'),
            value:'PENDING_CANCELLATION',
          },
          {
            label: this.$i18n.t('views.tracking.forms.enum.order_item_status.cancelled'),
            value:'CANCELLED',
          },
        ],
        in_imparfaite_warehouse: [
          { label: this.$i18n.t('views.products.enum.in_imparfaite_warehouse.yes'),
            value: 'true' },
          { label: this.$i18n.t('views.products.enum.in_imparfaite_warehouse.no'),
            value: 'false' },
        ],
        providers: [
          { label: 'Sendcloud', value: 'SENDCLOUD' },
          { label: 'Wing (PickPackShip)', value: 'WING_B2B' },
          { label: 'Externe', value: 'EXTERNAL' },
        ],
        shipment_kind: [
          { label: this.$i18n.t('views.products.enum.in_imparfaite_warehouse.yes'),
            value: 'FROM_IMPARFAITE_TO_CUSTOMER' },
          { label: this.$i18n.t('views.products.enum.in_imparfaite_warehouse.no'),
            value: 'FROM_STORE_TO_CUSTOMER' },
        ],
      },
    }
  },
  computed: {
    queryString: function () {
      let params = {
        page: this.currentPage,
        order: {},
        'order_item:order_reference': this.filters['order_item:order_reference'] || null,
        'shipment:status': this.filters['shipment:status'] || null,
        'order_item:status': this.filters['order_item:status'] || null,
        'order_item:product.sku:like': this.filters['order_item:product.sku:like'] || null,
        'order_item:product.in_imparfaite_warehouse': this.filters['order_item:product.in_imparfaite_warehouse']
            || null,
        'order_item:store.id' : this.filters['order_item:store.id'] || null,
        'shipment:recipient_email:like' : this.filters['shipment:recipient_email:like'] || null,
        'shipment:kind' : this.filters['shipment:kind'] || null,
        'shipment:provider' : this.filters['shipment:provider'] || null,
        'order_item:created_datetime:lte' : this.filters['order_item:created_datetime:lte'] || null,
        'order_item:created_datetime:gte' : this.filters['order_item:created_datetime:gte'] || null,
        'shipment:tracking_number:like' : this.filters['shipment:tracking_number:like'] || null,
      }

      return 'detailed_orders/?' + createQueryString(params)
    },
  },
  watch: {
    queryString: {
      handler: debounce(function () {
        this.syncUrl()
        this.load()
      }, 500),
      deep: true,
    },
    'table.options.page': function(value) {
      this.currentPage = value
    },
  },
  mounted() {
    this.load()
  },
  methods: {
    filterBy(by, value) {
      this.filters = {
        'order_item:order_reference': null,
        'shipment:status': null,
        'order_item:status': null,
        'order_item:product.sku:like': null,
        'order_item:product.in_imparfaite_warehouse': null,
        'order_item:store.name:like' : null,
        'shipment:recipient_email:like': null,
        'shipment:kind': null,
        'shipment:provider': null,
        'shipment:senderAddressCountryCode': null,
        'order_item:created_datetime:lte' : null,
        'order_item:created_datetime:gte' : null,
        'shipment:tracking_number:like' : null,
      }
      this.filters[by] = value
    },
    snackbar(color, message) {
      EventBus.$emit('SNACKBAR', { color: color, message: message })
    },
    getColor(item) {
      let blue = 50 * (item.order.order_reference % 5)
      let red = 40 * (item.order.order_reference % 6)

      return 'height:100%; background: rgb('+red+',' + blue + ', 200,  0.7)'
    },
    certifyConform(item) {
      this.table.loading = true
      this.snackbar(
        'info',
        this.$i18n.t('views.tracking.forms.messages.ready_to_be_prepared_for_customer_loading')
      )

      this.$axios.post('order_items/'+item.order.id+'/ready', {
        ready : item.order.ready_to_be_prepared_for_customer,
      })
        .then(() => {
          this.table.loading = false
          this.snackbar(
            'green',
            this.$i18n.t('views.tracking.forms.messages.ready_to_be_prepared_for_customer_success')
          )
        })
        .catch((error) => {
          this.table.loading = false
          let error_message = error.response.data['hydra:description'] ?? error.response.data['detail'] ?? 'No context'
          this.snackbar(
            'error',
            this.$i18n.t(
              'views.tracking.forms.messages.ready_to_be_prepared_for_customer_error',
              { error: error_message }
            )
          )
        })
    },
    executeTransfer() {
      this.table.loading = true
      this.snackbar(
        'info',
        this.$i18n.t('views.tracking.dialog.admin.transfer.loading')
      )

      this.$axios.post('order_items/'+this.editedItem.order.id+'/transfers', { force: true })
        .then(() => {
          this.snackbar(
            'green',
            this.$i18n.t('views.tracking.dialog.admin.transfer.success')
          )
          this.table.loading = false
          this.load()
        })
        .catch((error) => {
          let error_message = error.response.data['hydra:description'] ?? error.response.data['detail'] ?? 'No context'
          this.snackbar(
            'error',
            this.$i18n.t('views.tracking.dialog.admin.transfer.error', { error: error_message })
          )
          this.table.loading = false
          this.load()
        })
      this.close()
    },

    executeReturn(type) {
      this.table.loading = true
      this.snackbar(
        'info',
        this.$i18n.t('views.tracking.dialog.admin.return.loading')
      )

      this.$axios.post('order_items/'+this.editedItem.order.id+'/returns/request', {
        return_type : type,
      })
        .then(() => {
          this.snackbar(
            'green',
            this.$i18n.t('views.tracking.dialog.admin.return.success')
          )
          this.table.loading = false
          this.load()
          this.close()
        })
        .catch((error) => {
          let error_message = error.response.data['hydra:description'] ?? error.response.data['detail'] ?? 'No context'
          this.snackbar(
            'error',
            this.$i18n.t('views.tracking.dialog.admin.return.error', { error: error_message })
          )
          this.table.loading = false
          this.load()
          this.close()
        })
    },
    returnReceived(item){
      this.table.loading = true
      this.snackbar(
        'info',
        this.$i18n.t('views.tracking.dialog.admin.return_received.loading')
      )

      this.$axios.post('order_items/'+item.order.id+'/returns/receptions', {})
        .then(() => {
          this.snackbar(
            'green',
            this.$i18n.t('views.tracking.dialog.admin.return_received.success')
          )
          this.table.loading = false
          this.load()
          this.returnDialog.visible = false
        })
        .catch((error) => {
          let error_message = error.response.data['hydra:description'] ?? error.response.data['detail'] ?? 'No context'
          this.snackbar(
            'error',
            this.$i18n.t('views.tracking.dialog.admin.return_received.error', { error: error_message })
          )
          this.table.loading = false
          this.load()
          this.returnDialog.visible = false
        })
    },
    close () {
      this.deliveryDialog.visible = false
      this.dialog.visible = false
      this.adminDialog.visible = false
      this.returnDialog.visible = false
    },

    executeAddDelivery() {
      this.table.loading = true
      this.snackbar(
        'info',
        this.$i18n.t('views.tracking.dialog.admin.add_delivery_date.loading')
      )

      this.$axios.post('order_items/'+this.editedItem.order.id+'/delivers', {
        delivered_at: this.deliveryDialog.date,
      })
        .then(() => {
          this.snackbar(
            'green',
            this.$i18n.t('views.tracking.dialog.admin.add_delivery_date.success')
          )
          this.table.loading = false
          this.load()
          this.deliveryDialog.visible = false
        })
        .catch((error) => {
          let error_message = error.response.data['hydra:description'] ?? error.response.data['detail'] ?? 'No context'
          this.snackbar(
            'error',
            this.$i18n.t('views.tracking.dialog.admin.add_delivery_date.error', { error: error_message })
          )
          this.table.loading = false
          this.load()
          this.deliveryDialog.visible = false
        })
    },
    openDeliveryDialog(item) {
      this.editedItem = Object.assign({}, item)
      this.deliveryDialog.visible = true
    },
    openNotesDialog(item) {
      this.adminDialog.text = this.$i18n.t('views.tracking.dialog.admin.update_notes.text', {
        sku: item.order.product_sku,
        orderReference: item.order.order_reference,
        storeName: item.order.store_name,
      })

      this.adminDialog.notes = item.order.administrator_notes
      this.adminDialog.conform = item.order.ready_to_be_prepared_for_customer

      this.editedItem = Object.assign({}, item)
      this.adminDialog.visible = true
    },

    openTransferDialog(item) {
      this.dialog.text = this.$i18n.t('views.tracking.dialog.admin.transfer.text', {
        amount: this.formatPrice(item.order.amount_detail_paid_amount / 100),
        sku: item.order.product_sku,
        orderReference: item.order.order_reference,
        storeName: item.order.store_name,
      })

      this.editedItem = Object.assign({}, item)
      this.dialog.visible = true
    },
    openReturnOrderDialog(item) {
      this.returnDialog.text = this.$i18n.t('views.tracking.dialog.admin.return.text', {
        sku: item.order.product_sku,
        orderReference: item.order.order_reference,
        storeName: item.order.store_name,
      })
      this.editedItem = Object.assign({}, item)
      this.returnDialog.visible = true
    },
    openCancelReturnOrderDialog(item) {
      this.cancelReturnDialog.text = this.$i18n.t('views.tracking.dialog.admin.cancel_return.text', {
        sku: item.order.product_sku,
        orderReference: item.order.order_reference,
        storeName: item.order.store_name,
      })
      this.editedItem = Object.assign({}, item)
      this.cancelReturnDialog.visible = true
    },

    saveAdminDialog () {
      this.table.loading = true
      this.snackbar(
        'info',
        this.$i18n.t('global.loading')
      )

      this.$axios.post('order_items/'+this.editedItem.order.id+'/notes', {
        administrator_notes : this.adminDialog.notes,
      })
        .then(() => {
          this.table.loading = false
          this.snackbar(
            'green',
            this.$i18n.t('views.tracking.dialog.admin.update_notes.success')
          )
          this.load()
          this.close()

        })
        .catch((error) => {
          this.table.loading = false
          let error_message = error.response.data['hydra:description'] ?? error.response.data['detail'] ?? 'No context'
          this.snackbar(
            'error',
            this.$i18n.t('views.tracking.dialog.admin.update_notes.error', { error: error_message })
          )
          this.load()
          this.close()
        })
    },
    syncUrl () {
      this.$router.replace({ name: 'TrackingList', query: { ...this.filters, page : this.currentPage } })
    },
    load() {
      this.cancel()
      let axiosSource = this.$axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel }
      this.table.loading = true
      this.items = []
      this.totalItems = 0

      this.$axios.get(this.queryString, {
        cancelToken: axiosSource.token,
      })
        .then((response) => {
          this.items = response.data['items']
          this.totalItems = response.data['total']
          this.table.loading = false
        })
        .catch((error) => {
          if (!this.$axios.isCancel(error)) {
            // eslint-disable-next-line no-console
            console.log(error)
            this.table.loading = false
          }
        })
        .finally(() => {
          this.request = null
        })
    },
    cancel() {
      if (this.request) {
        this.request.cancel('aborted')
        this.request = null
      }
    },
    getImparfaiteOrderLink(orderId) {
      return process.env.VUE_APP_BO_BASE_URL + '/orders/' + orderId
    },
    formatPrice(value) {
      if (typeof value !== 'number') {
        return value
      }
      var formatter = new Intl.NumberFormat('FR', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
      })

      return formatter.format(value)
    },
  },
}
</script>

<style scoped>
.v-data-table >>> td {
    padding: 0px !important;
}
</style>
